<template>
    <div class="com">
        <div class="imgLi" v-for="(item, index) in imgList" :key="index">
            <div class="title">
                <span>{{ item.imgName }}</span>
                <van-uploader 
                    class="uploadText"
                    :after-read="(file) => afterRead(file, item)">
                    <div>重新上传</div>
                </van-uploader>
            </div>
            <div class="images">
                <div class="tips_img" v-if="item.tipImg">
                    <img :src="item.tipImg" mode="" @click="preImg(item.tipImg)"/>
                    <span class="textImg">示例图</span>
                </div>
                <img class="tips_img" @click="preImg(item.doman + item.picture)" :src="item.doman + item.picture" alt="">
            </div>
            <div class="tips">
                不合格原因：<span class="errorText">{{ item.audit_remark }}</span>
            </div>
            <div class="order_line"></div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { ImagePreview } from 'vant';
import { getGeoLocation } from '@/utils/index';
import { initWatermarkConfigData, generateCanvasImg } from '@/utils/uploadOSS';
import { driverUploadPic } from '@/apis/checkList';


export default {
    props: {
        unqualifiedImg: {
            type: Array,
            default: []
        },
        defaultPhoto: {
            type: Array,
            default: []
        },
        orderInfo: {
            type: Object | null,
            default: ''
        },
        orderId: {
            type: String | null,
            default: ''
        }
    },
    data(){
        return {
            imgList: [],
            location: '',
            uploadInfo: '',
        }
    },
    computed: {
        ...mapState({
            'configData': state => state.configData,
            'driverInfo': state => state.driverInfo     //聚合同步技师信息
        })
    },
    mounted(){
        this.initData();
    },
    methods: {
        initData(){
            let status = this.orderInfo.status;
            let imgList = JSON.parse(JSON.stringify(this.unqualifiedImg));
            let defaultPhotos = JSON.parse(JSON.stringify(this.defaultPhoto));

            for(let i=0; i<imgList.length; i++){
                let otherValid = true;
                for(let j=0; j<defaultPhotos.length; j++){
                    if(imgList[i].key == defaultPhotos[j].key){
                        imgList[i].imgName = defaultPhotos[j].name;
                        imgList[i].tipImg = defaultPhotos[j].picture;
                        otherValid = false;
                        break;
                    }
                }
                if(otherValid){
                    imgList[i].key = 'other';
                    imgList[i].imgName = '其它照片';
                    imgList[i].tipImg = '';
                }
            }
            this.imgList = imgList;
        },
        // 图片预览
        preImg(url){
            ImagePreview([url]);
        },
        // 上传文件
        async afterRead(files, e){
            console.log(e, 'kkkk')
            this.uploadInfo = e;
            this.$toast.loading({
                message: '努力上传中...',
                forbidClick: true, 
                duration: 0
            })
            let _this = this;
            let file = files.file;
            this.imgFile = file;
            if(!this.location){
                this.location = await getGeoLocation();
            }
            if(!this.location || !this.location.lat || !this.location.lng || !this.location.address){
                this.$notify({ 
                    type: 'danger',
                    message: '定位失败，请稍后重试!'
                });
                this.$toast.clear();
                return
            };
            let reader = new FileReader();
            reader.readAsDataURL(file);
            
            let configPrams = {
                configData: this.configData,
                orderInfo: this.orderInfo,
                location: this.location,
                driverInfo: this.driverInfo,
                keyName: this.uploadInfo.imgName,
                imgKey: this.uploadInfo.key
            }
            let configData = initWatermarkConfigData(configPrams);       //水印配置文件

            reader.onload = function(theFile) {
                let image = new Image();
                image.src = theFile.target.result;
                image.onload = function() {
                    let data = {
                        img: image,
                        w: this.width,
                        h: this.height,
                        configData,
                    }
                    generateCanvasImg(data).then((url)=>{
                        _this.uploadImg(url, configData.watermarkTime)
                    }).catch((err)=>{
                        _this.$notify({ 
                            type: 'danger',
                            message: 'OSS上传失败!'
                        });
                        _this.$toast.clear();
                    })
                }
            }
        },
        // 上传服务照片阿里云链接到服务器
        uploadImg(url, watermarkTime){
            if(url){
                let data = {
                    order: this.orderId,
                    key: this.uploadInfo.key,
                    ser: this.uploadInfo.ser,
                    url,
                    watermark_time: watermarkTime
                }
                driverUploadPic(data).then((res)=>{
                    // 删除其它照片
                    if(this.uploadInfo.key == 'other'){
                        let delDtata = {
                            order: this.orderId,
                            picture_id: this.uploadInfo.id
                        }
                        driverUploadPic(delDtata).then((res)=>{
                            this.$emit('success')
                        })
                    }else{
                        this.$emit('success')
                    }
                    this.$toast.clear();
                }).catch(()=>{
                    this.$notify({ 
                        type: 'danger',
                        message: '上传失败!'
                    });
                    this.$toast.clear();
                })
            }else{
                this.$notify({ 
                    type: 'danger',
                    message: '上传失败!'
                });
                this.$toast.clear();
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .imgLi{
        padding: 10px 0;
        box-sizing: border-box;
        .title{
            display: flex;
            justify-content: space-between;
            font-weight: bold;
            .uploadText{
                color: var(--theme-color);
            }
        }
        .images{
            display: flex;
            margin: 8px 0;
            justify-content: space-between;
            .tips_img{
                width: 160px;
                height: 123px;
                position: relative;
                img {
                    width: 100%;
                    height: 100%;
                }
                .textImg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: inline-block;
                    width: 42px;
                    height: 20px;
                    background: var(--theme-color);
                    opacity: 1;
                    border-radius: 4px 0px 4px 0px;
                    font-size: 12px;
                    font-family: PingFangSC-Medium;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 20px;
                }
            }
        }
        .errorText{
            color: #FB421A;
        }
        .order_line{
            border-bottom: 1px solid #EFEFEF;
            margin-top: 10px;
        }
    }
</style>