<template>
    <div class="com">
        <div class="closeBox" @click="close">
            <van-icon name="cross" size="22"/>
        </div>
        <div id="payCode" ref="payCode" class="code"></div>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2';
import { orderWxPay, orderPayStatus } from '@/apis/checkList'
export default {
    props: {
        orderInfo: {
            type: Object,
            default: null
        }
    },
    data(){
        return {
            timer: null
        }
    },
    mounted(){
        this.getCodeUrl()
    },
    beforeDestroy(){
        clearTimeout(this.timer);
    },
    methods: {
        getCodeUrl(){
            let data = {
                order_id: this.orderInfo.id
            }
            orderWxPay(data).then((res)=>{
                console.log(res, '付款码')
                let payData = res.data.data.pay_data;
                let codeUrl = payData.code_url;
                this.creatQrCode(codeUrl)
                this.getPayStatus()
            })
        },
        creatQrCode(url){
            let qrcode = new QRCode('payCode', {
                text: url,
                width: 200,
                height: 200
            })
        },
        getPayStatus(){
            clearTimeout(this.timer);
            let data = {
                order_id: this.orderInfo.id
            }
            orderPayStatus(data).then((res)=>{
                if(res.data.data.status == 1 || res.data.data.status == 3){
                    clearTimeout(this.timer);
                    this.$toast('支付成功');
                    this.$emit('success');
                }else{
                    this.timer = setTimeout(()=>{
                        this.getPayStatus();
                    }, 2000)
                }
            })
        },
        close(){
            clearTimeout(this.timer)
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
    .code{
        min-height: 200px;
        margin: 30px 0;
        display: flex;
        justify-content: center;
    }
    .closeBox{
        position: absolute;
        top: 20px;
        right: 20px;
    }
</style>