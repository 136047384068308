<template>
    <div class="signView">
        <div class="footer">
            <div @click="close" class="leftBtn btn">返 回</div> 
            <div @click="handleReset" class="resetBtn btn">清 空</div> 
            <div @click="handleGenerate" class="rightBtn btn">确 认</div>
        </div>
        <vue-esign 
            class="esign"
            ref="esign" 
            :width="screenWidth" 
            :height="screenHeight"
            bgColor="#fff" 
            :isCrop="false" />
        <div class="title">
            <div class="text">{{ title }}签名</div>
        </div>
    </div>
</template>

<script>
import { dataURLtoFile } from '@/utils/index';
import { putOSS } from '@/utils/uploadOSS';
import { driverUploadPic } from '@/apis/checkList';

export default {
    props: {
        signKey: {
            type: String,
            default: ''
        },
        orderId: {
            type: String | null,
            default: ''
        }
    },
    data(){
        return {
            screenWidth: document.documentElement.clientWidth - 100, // 屏幕宽度
		    screenHeight: document.documentElement.clientHeight - 50, // 屏幕高度
            resultImg: '',
            title: ''
        }
    },
    mounted(){
        if(this.signKey == 'owner_sign'){
            this.title = '车主'
        }else if(this.signKey == 'meet_sign'){
            this.title = '接车人'
        }else if(this.signKey == 'driver_sign'){
            this.title = '服务员'
        }else if(this.signKey == 'agreement'){
            this.title = '免责协议'
        }
    },
    methods: {
        close(){
            this.$emit('close')
        },
        handleReset () {
            this.$refs.esign.reset()
        },
        handleGenerate () {
            let that = this;
            this.$refs.esign.generate().then(res => {
                this.resultImg = res;
                let imgFile = dataURLtoFile(res, 'imgFile');
                let reader = new FileReader();
                reader.readAsDataURL(imgFile);
                reader.onload = function(theFile) {
                    let image = new Image();
                    image.src = theFile.target.result;
                    image.onload = function() {
                        that.newImage(image, this.width, this.height);
                    }
                }
            }).catch(err => {
                this.$toast('请签名后再提交');
            })
        },
        async newImage(img, width, height){
            let canvas = document.createElement('canvas');
            let cxt = canvas.getContext('2d');
            canvas.width = height;
            canvas.height = width;
            cxt.translate(height / 2, width / 2)
			cxt.rotate(270 * Math.PI / 180)
            cxt.drawImage(img, -width / 2, -height / 2, width, height);
            const imgBase64 = canvas.toDataURL('image/png', 0.7);
            let imgFile = dataURLtoFile(imgBase64, 'imgFile');
            try{
                let url = await putOSS(imgFile);
                this.uploadImg(url);
            }
            catch{
                this.$toast('上传失败');
                return
            }
        },
        // 上传服务照片阿里云链接到服务器
        uploadImg(url){
            if(url){
                if(this.signKey == 'agreement'){
                    this.$emit('success', url);
                    return
                }
                let data = {
                    order: this.orderId,
                    key: this.signKey,
                    ser: 1,
                    url
                }
                driverUploadPic(data).then((res)=>{
                    console.log(res, '上传')
                    if(res.data.code == 1){
                        this.$emit('success', url);
                    }else{
                        this.$toast(res.data.info);
                    }
                }).catch(()=>{
                    this.$toast('上传失败');
                })
            }else{
                this.$toast('上传失败');
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .signView{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #fff;
        display: flex;
        align-items: center;
        .footer{
            width: 50px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            overflow: hidden;
            .btn{
                width: 80px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                transform: rotate(90deg);
                color: #fff;
                font-size: 14px;
                border-radius: 5px;
            }
            .leftBtn{
                background: #e4e4e4;
                color: #666;
            }
            .resetBtn{
                background: #FB421A;
            }
            .rightBtn{
                background: var(--theme-color);
            }
        }
        .esign{
            border: 1px dashed #e4e4e4;
        }
        .title{
            width: 50px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            .text{
                width: 100px;
                transform: rotate(90deg);
            }
        }
    }
</style>