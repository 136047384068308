<template>
    <div class="info">
        <div class="title">
            <span>{{ driverImproveInfo.uploadData ? '上传救援师傅车牌' : '信息完善' }}</span>
            <van-icon name="cross" size="20" class="closeIcon" @click="close"/>
        </div>
        <div class="form">
            <template v-if="driverImproveInfo.validType == 1 || driverImproveInfo.validType == 2">
                <van-field v-model="form.phone" disabled label="手机号"/>
                <van-field v-model="form.name" label="真实姓名" placeholder="请输入真实姓名"/>
            </template>
            <!-- 
            24-10-10需求 暂时关闭上传救援车牌效验接单
            driverImproveInfo.uploadKey || 
            -->
            <div class="uploadView" v-if="driverImproveInfo.validType == 1 || driverImproveInfo.validType == 3">
                <div class="uploadLabel">
                    {{ driverImproveInfo.uploadData ? `识别车牌【${driverImproveInfo.uploadData.plateNumber}】与当前绑定车辆【${orderInfo.driver.license}】不一致，请确定后提交!` : '上传救援师傅车牌照片' }}
                </div>
                <div class="uploadTips">
                    注意：请上传服务当前订单的救援车辆，否则将影响结算与罚款！使用后置摄像头，保证车牌清晰可见！！
                </div>
                <div class="imageBox">
                    <van-uploader 
                        upload-text='上传救援师傅车牌照片'
                        max-count='1'
                        v-model="carImg"
                        :before-delete="delImage"
                        :after-read="afterRead"/>
                </div>
            </div>
        </div>
        <div class="btn" @click="submit">提 交</div>
    </div>
</template>

<script>
import { getGeoLocation } from '@/utils/index';
import { generateCanvasImg, initWatermarkConfigData } from '@/utils/uploadOSS';
import { driverUploadPic, licenseOCR, improveDriverInfo } from '@/apis/checkList';
export default {
    props: {
        order: {
            type: String,
            default: ''
        },
        orderInfo: {
            type: Object,
            default: null
        },
        location: {
            type: Object | String,
            default: ''
        },
        configData: {
            type: Object | String,
            default: ''
        },
        disDriverInfo: {
            type: Object | String,
            default: ''
        },
        /*
            driverImproveInfo: {
                phone: '',          //手机号
                name: '',
                uploadKey: '',      //救援车牌照key    配置了救援车牌模板的订单需上传车头照
                uploadKeyName       //救援车牌照uploadKeyName
                validType: '',      //完善信息类型  1: 未注册、个体未实名 完善姓名手机号车头照     2：归属服务商未实名 完善姓名手机号   3： 个体已实名 完善车头照
                uploadData: '',     //订单流程车牌照片上传参数
            }
        */ 
        driverImproveInfo: {
            type: Object | String,
            default: ''
        }
        
    },
    data(){
        return {
            form: {
                driver_id: '',
                order_id: '',
                pic: '',
                pic_key: '',
                name: '',
                phone: ''
            },
            carImg: [],
            plateNumber: '',        //车牌
            imgFile: ''         //车牌照文件
        }
    },
    mounted(){
        this.initData()
    },
    methods: {
        initData(){
            // let info = JSON.parse(JSON.stringify(this.driverImproveInfo))
            let info = this.driverImproveInfo
            this.form.phone = info.phone
            this.form.name = info.name
            if(info.uploadData){
                this.imgFile = info.uploadData.file.file
                this.form.pic_key = info.uploadData.pic_key
                this.form.pic = info.uploadData.pic_url
                this.carImg = [info.uploadData.file]
                this.plateNumber = info.uploadData.plateNumber
            }
        },
        // 上传救援车辆照片
        afterRead(e){
            console.log(this.carImg, '---------')
            let formData = new FormData();
            formData.append('file', e.file)
            this.$toast.loading({
                message: '自动识别中...',
                forbidClick: true,
                overlay: true,
                duration: 0,
                loadingType: 'spinner',
            });

            licenseOCR(formData).then((res)=>{
                console.log(res, '---------')
                if(res.data.code == 1){
                    let data = res.data.data
                    let plateNumber = data.plate_number;
                    let pic_key = data.key;
                    let pic = data.url;

                    if(plateNumber == this.orderInfo.car_no){
                        this.$notify({ 
                            type: 'danger',
                            message: '救援车辆车牌不能与车主一致！请重新上传'
                        });
                        this.delImage()
                        this.$toast.clear();
                        return
                    }
                    this.form.pic_key = pic_key
                    this.form.pic = pic
                    this.plateNumber = plateNumber;
                    this.$toast(`${plateNumber}识别成功`)
                    this.imgFile = e.file;
                }else{
                    this.delImage()
                    this.$notify({ 
                        type: 'danger',
                        message: res.data.info
                    });
                    this.$toast.clear()
                }
            }).catch(()=>{
                this.delImage()
                this.$toast.clear();
            })
        },
        // 删除车牌照片
        delImage(){
            this.carImg = []
            this.plateNumber = ''
        },
        // 提交
        async submit(){
            if(!this.form.name){
                this.$toast('请填写真实姓名');
                return
            }else{
                if(this.form.name.length > 1){
                    let chineseCharactersCount = this.form.name.replace(/[\u4e00-\u9fa5]/g, '').length
                    if(chineseCharactersCount){
                        this.$toast('姓名不规范，请重新输入');
                        return
                    }
                }else{
                    this.$toast('姓名不规范，请重新输入');
                    return
                }
            }
            // this.driverImproveInfo.uploadKey ||          24-10-10需求 暂时关闭上传救援车牌效验接单
            if((this.driverImproveInfo.validType == 1 || this.driverImproveInfo.validType == 3) && !this.plateNumber){
                this.$toast('请上传救援师傅车牌照片！');
                return
            }
            this.$toast.loading({
                message: '信息提交中...',
                forbidClick: true,
                overlay: true,
                duration: 0,
                loadingType: 'spinner',
            });
            
            // uploadKey: '',      //救援车牌照key    配置了救援车牌模板的订单需上传车头照
            // validType: '',      //完善信息类型  1: 未注册、个体未实名 完善姓名手机号车头照     2：归属服务商未实名 完善姓名手机号   3： 个体已实名 完善车头照

            let data = {
                order_id: this.orderInfo.id,
                mobile: this.form.phone,
                name: this.form.name,
                pic_key: this.form.pic_key,
                pic: this.form.pic,
            }
            improveDriverInfo(data).then((res)=>{
                if(res.data.code == 1){
                    if(this.driverImproveInfo.uploadKey){
                        // 配置了救援车牌需上传
                        this.beforeOrderImgUpload()
                    }else{
                        this.$toast('提交成功！');
                        this.$emit('success');
                    }
                }
            })
        },
        async beforeOrderImgUpload() {
            const _this = this;
            let file = this.imgFile;
            let keyName = this.driverImproveInfo.uploadKeyName;
            let imgKey = this.driverImproveInfo.uploadKey

            let location;

            // location = {
            //     lat: 22.222222,
            //     lng: 113.1115555,
            //     address: '测试地址'
            // }

            try{
                location = await getGeoLocation();
            }catch(err){
                location = _this.location;
            }

            if(!location || !location.lng || !location.lat){
                this.$toast.clear();
                this.$notify({
                    type: 'danger',
                    message: '获取当前位置失败！'
                })
                return
            }
            let reader = new FileReader();
            reader.readAsDataURL(file);

            let configPrams = {
                configData: this.configData,
                orderInfo: this.orderInfo,
                location: location,
                driverInfo: this.disDriverInfo,
                keyName,
                imgKey
            }
            let configData = initWatermarkConfigData(configPrams);       //水印配置文件
            reader.onload = function(theFile) {
                let image = new Image();
                image.src = theFile.target.result;
                image.onload = function() {
                    let data = {
                        img: image,
                        w: this.width,
                        h: this.height,
                        configData,
                    }
                    generateCanvasImg(data).then((url)=>{
                        _this.uploadImg(url, configData.watermarkTime)
                    }).catch((err)=>{
                        _this.$notify({ 
                            type: 'danger',
                            message: 'OSS上传失败!'
                        });
                    })
                }
            }
        },
        // 上传服务照片阿里云链接到服务器
        uploadImg(url, watermarkTime){
            if(url){
                let key = this.driverImproveInfo.uploadKey
                let data = {
                    order: this.order,
                    key,
                    ser: 1,
                    url,
                    watermark_time: watermarkTime
                }
                driverUploadPic(data).then((res)=>{
                    console.log(res, '上传成功')
                    if(res.data.code == 1){
                        this.$toast('提交成功！');
                        this.$emit('success');
                    }else{
                        this.$notify({ 
                            type: 'danger',
                            message: res.data.info
                        });
                    }
                }).catch(()=>{
                    this.$notify({ 
                        type: 'danger',
                        message: '服务器错误,照片上传失败'
                    });
                })
            }else{
                this.$notify({ 
                    type: 'danger',
                    message: '照片上传失败!'
                });
            }
        },
        close(){
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
    .info{
        .closeIcon{
            position: absolute;
            top: 10px;
            right: 10px;
        }
        .title{
            height: 44px;
            text-align: center;
            line-height: 44px;
            font-size: 15px;
            font-weight: bold;
        }
        .form{
            padding: 0 16px;
            box-sizing: border-box;
            .uploadView{
                padding: 0 14px;
                box-sizing: border-box;
            }
            .uploadLabel{
                color: #000;
                font-size: 14px;
                font-weight: bold;
                margin: 20px 0 8px;
                line-height: 18px;
            }
            .uploadTips{
                color: #F01C1C;
                font-size: 12px;
                line-height: 18px;
                margin-bottom: 8px;
            }
            .imageBox {
                width: 271px;
                height: 230px;
                margin: 0 auto;
            }
        }
        .btn{
            width: 283px;
            height: 40px;
            background: var(--theme-color);
            color: var(--theme-btn);
            text-align: center;
            line-height: 40px;
            border-radius: 50px;
            margin: 20px auto;
            font-weight: bold;
            font-size: 15px;
        }
    }
</style>