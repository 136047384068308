<template>
    <div class="npsUploadCom">
        <div class="titleBox">
            <span>{{ uploadInfo.name }}</span>
            <van-icon name="cross" size="20" class="closeIcon" @click="close"/>
        </div>
        <div class="tips1">服务已完成,请注意引导客户参与五星好评!</div>
        <div class="tips2">
            <span>注意一定是五星好评，</span>
            <span class="tips3">非五星好评按差评处理!</span>
        </div>
        <div class="tipsBox">
            <div class="tipsTitle">服务话术</div>
            <div class="tipsText">老师!请问您对我的服务是否满意，如果满意话，请给我一个五星好评，因为这个评价对我很重要，谢谢!</div>
        </div>
        <div class="uploadImgBox" v-show="uploadInfo">
            <div class="imageBox">
                <img :src="uploadInfo.picture" mode=""/>
                <span class="textImg">示例图</span>
            </div>
            <div class="imageBox uploaderBox">
                <van-uploader 
                    upload-text='上传凭证'
                    :after-read="(file) => afterRead(file)"
                    max-count='1'
                    v-model="uploadInfo.imgList"/>
            </div>
        </div>
        <div class="tipsList">
            <div class="title">注意事项</div>
            <div class="tipsLi">
                1、五星好评端口: 客户短信链接或APP上；
            </div>
            <div class="tipsLi">
                2、若客户因时效或态度对服务有不满意倾向，不得强行向客户索要五星好评，避免客户差评或投诉，该种情况下向调度报备，不再对该案件进行好评;
            </div>
            <div class="tipsLi">
                3、可以引导客户完成五星好评，但禁止主动索要客户手机点五星好评；
            </div>
        </div>
        <div class="footerBox" @click="affirm">
            确 认
        </div>
    </div>
</template>

<script>
import { getGeoLocation } from '@/utils/index';
import { driverUploadPic } from '@/apis/checkList';
import { generateCanvasImg, initWatermarkConfigData } from '@/utils/uploadOSS';
export default {
    props: {
        uploadNpsInfo: {
            type: Object | null,
            default: null
        },
        configData: {
            type: Array | null,
            default: []
        },
        orderInfo: {
            type: Object | null,
            default: null
        },
        driverInfo: {
            type: Object | null,
            default: null
        },
        order: {
            type: String,
            default: ''
        }
    },
    mounted(){
        console.log(this.uploadNpsInfo, this.configData, this.orderInfo, this.driverInfo,  'uploadNpsInfo')
        this.uploadInfo = JSON.parse(JSON.stringify(this.uploadNpsInfo))
    },
    data(){
        return {
            uploadInfo: '',
            location: '',
        }
    },
    methods: {
        afterRead(file){
            let obj = {
                url: '',
                status: 'uploading',
                message: '上传中...',
            };
            this.uploadInfo.imgList = [obj];
            this.beforeOrderImgUpload(file.file);
        },
        async beforeOrderImgUpload(file) {
            const _this = this;
            let location = this.location;
            if(!location || !location.lat || !location.lng || !location.address){
                try{
                    location = await getGeoLocation();
                    this.location = location
                }catch(err){
                    this.$notify({ 
                        type: 'danger',
                        message: '未授权获取当前定位!'
                    });
                    this.uploadInfo.imgList = [];
                    return
                }
            }
            let reader = new FileReader();
            reader.readAsDataURL(file);

            let configPrams = {
                configData: this.configData,
                orderInfo: this.orderInfo,
                location: this.location,
                driverInfo: this.driverInfo,
                keyName: this.uploadInfo.name,
                imgKey: this.uploadInfo.key
            }
            let configData = initWatermarkConfigData(configPrams);       //水印配置文件

            reader.onload = function(theFile) {
                let image = new Image();
                image.src = theFile.target.result;
                image.onload = function() {
                    let data = {
                        img: image,
                        w: this.width,
                        h: this.height,
                        configData,
                    }
                    generateCanvasImg(data).then((url)=>{
                        _this.uploadImg(url, configData.watermarkTime)
                    }).catch((err)=>{
                        console.log(err)
                        _this.$notify({ 
                            type: 'danger',
                            message: 'OSS上传失败!'
                        });
                        _this.uploadInfo.imgList = [];
                    })
                }
            }
        },
        // 上传服务照片阿里云链接到服务器
        uploadImg(url, watermarkTime){
            if(url){
                let data = {
                    order: this.order,
                    key: this.uploadInfo.key,
                    ser: 1,
                    url,
                    watermark_time: watermarkTime
                }
                driverUploadPic(data).then((res)=>{
                    if(res.data.code == 1){
                        let obj = {
                            url: url,
                            status: 'done',
                            message: '上传成功'
                        };
                        this.uploadInfo.id = res.data.data[0].id;
                        this.uploadInfo.imgList = [obj];
                        console.log(this.uploadInfo, 'this.uploadInfo')
                        if(this.npsUpload){
                            this.$toast('上传成功！')
                            this.npsUpload = false;
                        }
                    }else{
                        this.$notify({ 
                            type: 'danger',
                            message: res.data.info
                        });
                        this.uploadInfo.imgList = [];
                    }
                }).catch(()=>{
                    this.$notify({ 
                        type: 'danger',
                        message: '服务器错误'
                    });
                    this.uploadInfo.imgList = [];
                })
            }else{
                this.$notify({ 
                    type: 'danger',
                    message: '照片上传失败!'
                });
                this.uploadInfo.imgList = [];
            }
        },
        // 关闭窗口
        close(){
            this.$emit('close')
        },
        // 确认
        affirm(){
            if(this.uploadInfo.imgList.length == 0){
                this.$notify({ 
                    type: 'danger',
                    message: `请上传${this.uploadInfo.name}!`
                });
                return
            }
            this.close()
        }
    }
}
</script>

<style lang="scss" scoped>
    .npsUploadCom{
        padding: 0 16px 20px;
        box-sizing: border-box;
        .closeIcon{
            position: absolute;
            top: 10px;
            right: 10px;
        }
        .titleBox{
            height: 44px;
            text-align: center;
            line-height: 44px;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 5px;
        }
        .tips1{
            font-weight: bold;
            font-size: 15px;
            color: #000000;
            line-height: 20px;
            margin-bottom: 4px;
        }
        .tips2{
            font-size: 13px;
            color: #666;
            line-height: 20px;
            margin-bottom: 16px;
            .tips3{
                color: #F01C1C;
            }
        }
        .tipsBox{
            background: #FAFAFA;
            padding: 8px;
            box-sizing: border-box;
            margin-bottom: 16px;
            .tipsTitle{
                font-weight: bold;
                font-size: 14px;
                color: var(--theme-btn);
                line-height: 20px;
                margin-bottom: 8px;
            }
            .tipsText{
                font-size: 13px;
                color: #666666;
                line-height: 18px;
            }
        }
        .uploadImgBox {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            flex-wrap: nowrap;
            margin-bottom: 15px;
            .imageBox {
                width: 150px;
                height: 113px;
                position: relative;
                img {
                    width: 150px;
                    height: 113px;
                    border-radius: 5px;
                }
                .textImg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: inline-block;
                    width: 42px;
                    height: 20px;
                    background: var(--theme-color);
                    opacity: 1;
                    border-radius: 5px 0px 5px 0px;
                    font-size: 10px;
                    font-family: PingFangSC-Medium;
                    color: var(--theme-btn);
                    text-align: center;
                    line-height: 20px;
                }
            }
        }
        .tipsList{
            margin-bottom: 20px;
            .title{
                font-weight: bold;
                font-size: 14px;
                color: var(--theme-btn);
                line-height: 20px;
                margin-bottom: 8px;
            }
            .tipsLi{
                font-size: 13px;
                color: #999999;
                line-height: 18px;
            }
        }
        .footerBox{
            height: 36px;
            background: var(--theme-color);
            border-radius: 50px;
            font-weight: bold;
            font-size: 15px;
            color: var(--theme-btn);
            text-align: center;
            line-height: 36px;
        }
    }
</style>