<template>
    <div class="compressBox">
        <div class="jsonImgBox">
            <div>
                {{ uploadIndex }} / {{ imgArr.length }}
            </div>
            <van-field
                v-model="imgJson"
                rows="5"
                autosize
                label=""
                type="textarea"
                placeholder="请输入留言"/>
        </div>
        <div class="footer">
            <!-- <div class="btn" @click="compress">
                确认压缩
            </div> -->
        </div>
    </div>
</template>

<script>
import { generateCanvasImg } from '@/utils/uploadOSS';
import { driverUploadPic } from '@/apis/checkList';
import IMGJSON from './img.json'

export default {
    data(){
        return {
            imgJson: '',
            imgArr: IMGJSON,
            errorArr: [],
            uploadIndex: 0,
        }
    },
    mounted(){
        this.imgJson = JSON.stringify(IMGJSON)
    },
    methods: {
        async compress(){
            if(!this.imgJson) return
            this.errorArr = []
            let imgArr = IMGJSON;
            // let imgArr = []
            let _this = this;
            for(let i=0; i<imgArr.length; i++){
                let imgInfo = imgArr[i];
                let res = await _this.uploadImg(imgInfo)
                console.log(i+1, imgInfo.order, imgInfo.key, 'imgInfo')
                this.uploadIndex = i+1 == imgArr.length ? 0 : i+1;
            }
        },
        uploadImg(imgInfo){
            return new Promise((resolve, reject) => {
                let image = new Image();
                image.src = imgInfo.url;
                image.setAttribute("crossOrigin",'Anonymous')
                image.onload = async function() {
                    let data = {
                        img: image,
                        w: this.width,
                        h: this.height,
                        configData: ''
                    }
                    generateCanvasImg(data).then((url)=>{
                        let postData = {
                            order: imgInfo.order,
                            key: imgInfo.key,
                            ser: 1,
                            url
                        }
                        driverUploadPic(postData).then((res)=>{
                            resolve(res)
                        }).catch(()=>{
                            console.log('上传错误')
                            this.errorArr.push(imgInfo)
                            console.log(errorArr, 'errorArr1')
                        })
                    }).catch((err)=>{
                        console.log(err, 'err')
                        this.errorArr.push(imgInfo)
                        console.log(errorArr, 'errorArr2')
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .progress{
        padding: 20px 10px 10px;
    }
    .compressBox{
        padding-bottom: 64px;
    }
    .jsonImgBox{
        border: 1px solid #999;
        width: 340px;
        margin: 20px auto 0;
    }
    .footer{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 64px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        .btn{
            width: 340px;
            height: 44px;
            background: var(--theme-color);
            border-radius: 40px;
            text-align: center;
            line-height: 44px;
            color: #fff;
        }
    }
</style>